<template>
    <section>
        <v-row no-gutters>
            <v-col cols="9">
                <section class="description_div roboto f14 fw500 secondary-1--text" >
                    <b>{{module.title ? module.title : 'Untitled' }}</b> 
                </section>
            </v-col>
            <v-col cols="3">
                <section class="d-flex flex-row">
                    <v-icon size="18" class="float-right ml-auto" v-if="module.module_is_locked || (module.valid_until === 'lock' ? ( index > 0 ? !prev_module_done : true) : false)"> mdi-lock-outline </v-icon>
                    <v-badge
                        bordered
                        size='10'
                        inline
                        :class="!(module.module_is_locked || (module.valid_until === 'lock' ? ( index > 0 ? !prev_module_done : true) : false)) && 'ml-auto'"
                        :color="moduleIsDone ? 'success' : 'warning'"
                    />
                </section>
            </v-col>
        </v-row>
        
        <div class="f10 secondary-2--text mt-1 mb-3 " v-if="module.module_is_locked">
            This module will be accessible on {{ $dateFormat.mmDDyy(module.valid_until) }}
        </div>
        <section v-if="module.description" class="description_div f12 secondary-2--text mt-1" v-html="module.description ? $dompurifier(module.description) : ''"></section>
    </section>
</template>

<style scoped>

.description_div {
  white-space: normal !important;
  word-wrap: break-word !important;
}

</style>

<script>

export default {
    props: ['module', 'index', 'prev_module_done'],

    computed: {
        moduleIsDone() {
            if(!this.module) return false

            return [this.module.module_topic_is_done, this.module.module_subtopic_is_done, this.module.module_assessment_is_done, this.module.module_evaluation_is_done].every((item) => item === true)
        
        }
    }
}

</script>